<!-- eslint-disable vue/valid-v-model -->
<template>
  <div class="home">
    <van-nav-bar title="标题" left-arrow>
      <template #left>
        <van-icon name="arrow-left" size="20" @click="$router.replace('/')" />
      </template>
      <template #title>
        <h3 class="title">
          {{ info.serviceTypeId ? idsFormat(info.serviceTypeId, 'jiazheng_service_type').join(' ') : "" }} - {{
              info.name
          }}
        </h3>
      </template>
    </van-nav-bar>
    <van-row>
      <van-col span="24">
        <van-cell>
          <template #title>
            <van-image  v-if="info.avatar" :src="apiUrl + '/' + info.avatar" />
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <h4>基本信息</h4>
          </template>
        </van-cell>
        <van-cell>
          <template #title>姓名: <span style="color:#969799;">{{ info.name }}</span></template>
        </van-cell>
        <van-cell>
          <template #title>身高: <span style="color:#969799;">{{info.height?info.height+'cm':''}}</span></template>
        </van-cell>
        <van-cell>
          <template #title>体重: <span style="color:#969799;">{{info.weight?info.weight+'kg':''}}</span></template>
        </van-cell>
        <van-cell>
          <template #title>年龄: <span style="color:#969799;">{{ info.dob | filterAge
          }}/{{ info.ayiZodiac }}</span></template>
        </van-cell>
        <van-cell> <template #title>籍贯: <span style="color:#969799;">{{ formatArea2(info.ayiCityId) }}</span></template>
        </van-cell>
        <van-cell> <template #title>经验: <span
              style="color:#969799;">{{ dictFormat('jiazheng_ayi_experience', info.ayiExperienceId) }}</span></template>
        </van-cell>
        <van-cell> <template #title>证书: <span style="color:#969799;">{{
            info.ayiCertificateId ? idsFormat(info.ayiCertificateId, 'jiazheng_ayi_certificate').join(' '):''}}</span></template> </van-cell>
        <van-cell> <template #title>标签: <span style="color:#969799;">{{
            info.ayiTagId ? idsFormat(info.ayiTagId, 'jiazheng_ayi_tag').join(' ') : ''
        }}</span></template> </van-cell>
        <van-cell>
          <template #title>
            <h4>阿姨评价</h4>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <div class="description" style="color:#969799;" v-html="info.description"></div>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <h4>工作信息</h4>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <div class="workExperience" v-html="info.workExperience"></div>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <h4>才艺图片</h4>
            <div class="imgs" v-if="info.caiyiImgs">
              <van-image :key="item" v-for="item in caiyiImgsArr"
              fit="cover"
              lazy-load
              :src="item" @click="setData('caiyiShow',true)" />
            </div>
          </template>
        </van-cell>
        <van-image-preview v-model="caiyiShow" :images="caiyiImgsArr">
          <!-- <template v-slot:index>第{{ index }}页</template> -->
        </van-image-preview>

        <van-cell>
          <template #title>
            <h4>技能图片</h4>
            <div class="imgs" v-if="info.skillImgs">
              <van-image :key="item" v-for="item in skillImgsArr"
              fit="cover"
              lazy-load
              :src="item" @click="setData('caiyiShow',true)" />
            </div>
          </template>
        </van-cell>
        <van-image-preview v-model="caiyiShow" :images="caiyiImgsArr">
          <!-- <template v-slot:index>第{{ index }}页</template> -->
        </van-image-preview>
        <van-cell>
          <template #title>
            <video width="100%" v-if="info.video" :src="apiUrl + '/' + info.video" controls="controls" />
          </template>
        </van-cell>
      </van-col>
    </van-row>
  </div>
</template>
<script>
import jiazheng from "@/mixins/jiazheng";
import homeIcon from '@/assets/image/home-heart-fill.png'
import handIcon from '@/assets/image/hand-heart-fill.png'
import heartsIcon from '@/assets/image/hearts-fill.png'
import priceIcon from '@/assets/image/price-tag-3-fill.png'
export default {
  mixins: [jiazheng],
  data: function () {
    return {
      icon: {
        homeIcon,
        handIcon,
        heartsIcon,
        priceIcon,
      },
      title: "",
      showMenu: false,
      loading: false,
      caiyiShow:false,
      refreshing: false,
      finished: false,
      caiyiImgsArr:[],
      skillImgsArr:[],
      info: {},
    };
  },
  watch: {

  },
  created() {
    this.showMenu = true;
    this.load();
  },
  methods: {
    setData(k, v) {
      console.log(k, v);
      this[k] = v;
    },
    load: function () {
      this.request({
        url: this.apiUrl + "/ayi/get",
        method: "get",
        params: {
          uuid: this.$route.query.uuid,
        },
      }).then(res => {
        this.caiyiImgsArr = res.data.caiyiImgs.split(',').map(v=>{
          return this.apiUrl+'/'+v
        })
        this.skillImgsArr = res.data.skillImgs.split(',').map(v=>{
          return this.apiUrl+'/'+v
        })
        this.info = res.data
        console.log(res)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding-bottom: 180px;
}


/deep/ {
  .workExperience {
    color:#969799;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 15px;
      color:#646566;
    }
  }
}
.imgs{
  // display: flex;
  .van-image{
    // width:calc(50% - 20px);
    // &:nth-child(2n){
    //   margin-left:20px;
    // }
  }
}
</style>